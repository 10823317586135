<template>
  <div class="modal fade" id="alertSettingModal" tabindex="-1" aria-labelledby="alertSettingModalLabel" aria-hidden="true"
    ref="modalDom" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t("abnormal-settings") }} <!--{{ mac_address }}--> </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div>
            <!-- 表格設定頁面 -->
            <div v-for="(notification, index) in conditions" :key="index" class="d-flex align-items-center border-bottom">
              <div class="">
                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked />
              </div>
              <div class="border-left border-right w-25 text-center">
                {{ $t(notification.code) }}
              </div>

              <div class="flex-grow-1" v-html="notification.description" />
              <div class="text-nowrap ">
                <button class="btn btn-primary btn-sm mx-1">{{ $t("edit") }}</button>
                <button class="btn btn-danger btn-sm " @click="deleteCondition(notification.id)">
                  {{ $t("delete") }}
                </button>
              </div>
            </div>

            <hr />
            <!-- 以下為新增新的告警項目 -->
            <div>
              <div class="row">
                <div class="col-3 text-center">
                  <!-- {{ $t("add-abnormal-condition") }} -->
                  {{ $t("abnormal-name") }}
                </div>
                <div class="col-9">
                  <input class="form-control text-center" type="text" :placeholder="$t('enter-abnormal-code')"
                    v-model="formValues.name" />
                </div>
              </div>
              <div class="row">
                <div class="col-3 text-center">
                  {{ $t("abnormal-code") }}
                </div>

                <div class="col-9">
                  <LocalesEdit :errors="errors" ref="localeEditRef" :touched="meta.touched" />
                </div>
              </div>
            </div>
            <hr />
            <div class="d-flex align-items-center">
              <div class="text-end">{{ $t("at") }}</div>
              <div class="">
                <input class="form-control form-control-sm text-center" type="number"
                  :placeholder="$t('enter-time-duration')" aria-label=".form-time" v-model="formValues.prdDuration" />
              </div>
              <div class="">
                <select class="form-select-sm" aria-label="output type" v-model="formValues.prodUnit">
                  <option value="1">{{ $t("unit.second") }}</option>
                  <!--秒鐘-->
                  <option value="2">{{ $t("unit.minute") }}</option>
                  <!--分鐘-->
                  <option value="3">{{ $t("unit.hour") }}</option>
                  <!--小時-->
                  <option value="4">{{ $t("day") }}</option>
                  <!--天-->
                </select>
              </div>
              <div>{{ $t("within") }}</div>
              <div class="">
                <select class="form-select-sm" aria-label="action-type" @change="selectChange($event)"
                  v-model="formValues.prdOccur">
                  <option value="0">{{ $t("not-occur") }}</option>
                  <option value="1">{{ $t("occur") }}</option>
                </select>
              </div>
              <div class="">
                <select class="form-select-sm" aria-label="output-type" v-model="formValues.prdType">
                  <option value="1">{{ $t("stool") }}</option>
                  <option value="2">{{ $t("urine") }}</option>
                </select>
              </div>
            </div>
            <div class="text-center d-flex align-items-center" v-if="toggleAccmulate">
              <div class="text-end">{{ $t("and") }}</div>
              <div class="">
                <select class="form-select-sm" aria-label="count_threshold" v-model="formValues.frqCond">
                  <option value="0">{{ $t("not") }}</option>
                  <option value="1">
                    {{ $t("number-counts") + " " + $t("less-than") }}
                  </option>
                  <option value="2">
                    {{ $t("number-counts") + " " + $t("greater-than") }}
                  </option>
                </select>
              </div>
              <div class="">
                <input class="form-control form-control-sm text-center" type="number"
                  :placeholder="$t('enter-excretion-times')" aria-label=".form-time" v-model="formValues.frqCount" />
              </div>
              <div class="text-center">{{ $t("number-count") }}</div>
            </div>
            <div class="text-center d-flex align-items-center" v-if="toggleAccmulate">
              <div class="text-end">{{ $t("and") }}</div>
              <div class="">
                <select class="form-select-sm" aria-label="count_threshold" v-model="formValues.accType">
                  <option value="0">{{ $t("none") }}</option>
                  <option value="1">
                    {{ $t("single-time") + " " + $t("weight") }}
                  </option>
                  <!-- 單次重量 -->
                  <option value="2">
                    {{ $t("accumulated") + " " + $t("weight") }}
                  </option>
                  <!-- 累積重量 -->
                </select>
              </div>
              <div class="">
                <select class="form-select-sm" aria-label="count_threshold" v-model="formValues.accCond">
                  <option value="0">{{ $t("none") }}</option>
                  <option value="1">{{ $t("less-than") }}</option>
                  <option value="2">{{ $t("greater-than") }}</option>
                </select>
              </div>
              <div class="">
                <input class="form-control form-control-sm form-inline text-center" type="number"
                  :placeholder="$t('enter-excretion-weight')" aria-label=".form-time" v-model="formValues.accWeight" />
              </div>
              <div class="">
                <select class="form-select-sm" aria-label="count_threshold" v-model="formValues.accUnit">
                  <option value="0">{{ $t("none") }}</option>
                  <option value="1">{{ $t("measure.g") }}</option>
                  <option value="2">{{ $t("measure.kg") }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <!-- errors:{{ errors }} -->
            </div>
          </div>
          <div class="row">
            <div class="col">
              <!-- meta:{{ meta }} -->
            </div>
          </div>
          <div class="row">
            <div class="col">
              <!-- values:{{ values }} -->
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="test">
            {{ $t("test") }}
          </button>
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            {{ $t("close") }}
          </button>
          <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 這是新的的 告警異常設定 使用在新版的 資訊平台 使用 bootstrap 的 modal
// 具體需要改進的部份如下
/*
  1. 資料來源存放於 db 透過 ubus 取得清單 
  2. 不同的設備 ID 會有不同的設定值 (需要透過 props 傳值 )
  3. 可透過搜尋的方式找到預先儲存的設定值
*/

import { defineComponent, computed, ref, reactive, onMounted } from "vue";
import { Modal } from "bootstrap"; // Collapse move to component
// import i18n from "@/language/";
import { useStore } from 'vuex';
import { useI18n } from "vue-i18n";
import { useForm, useFieldArray } from "vee-validate";
import LocalesEdit from '@/components/ui/LocalesEdit.vue';
// import { required } from "@vee-validate/rules";
import * as yup from 'yup';

export default defineComponent({
  name: "NotifySetting",
  props: {
    mac_address: {
      type: String,
      required: true,
    },
  },
  components: {
    LocalesEdit // eslint-disable-line
  },
  setup(props, { emit }) {
    const schema = yup.object().shape({
      locales:
        yup.array()
          .of(
            yup.object({
              name: yup.string().required().label('name'),
              locale: yup.string().required().label('locale'),
            })
          ).strict(),
    });

    const { t, locale, mergeLocaleMessage, availableLocales: languages } = useI18n();

    const store = useStore();

    const { handleSubmit, resetForm, errors, meta, validate, values } = useForm({
      initialValues: {
        locales: [],
      },
      validationSchema: schema
    });

    const { remove: delLocale, push: addLocale, fields: localeList } = useFieldArray("locales");

    const addNewLocale = () => {
      addLocale({ name: "", locale: "" })
    }


    const modalDom = ref(null); // 實際的Modal DOM
    let NotifySettingsInstance;
    let notifyConditions1 = [];

    const notifyConditions2 = computed(() => {
      return store.getters["ubus/getNotificationList"];
    });


    onMounted(async () => {
      // console.log("PatientEdit onMounted:", { bedTarget });
      modalDom.value.addEventListener("hidden.bs.modal", () => {
        emit("update:mac_address", ""); // 當modal隱藏時將 v-model 的值清空
        resetForm()
      });
      modalDom.value.addEventListener("shown.bs.modal", () => {
        // addLocale({ name: "", locale: locale.value })
        localeEditRef.value.addLocale(locale.value)
      });

      NotifySettingsInstance = Modal.getOrCreateInstance(modalDom.value);

      await store.dispatch("ubus/getNotificationList");
    });



    let toggleAccmulate = ref(true); // 用來關閉條件2及3 ...

    let notifyConditions = ref([
      {
        peroid: {
          id: 1,
          name: "排尿異常",
          code: "abnormal-urination",
          // condition: 2, // 1:低於 2:超過
          duration: 4,
          unit: 3, // 1:秒 2:分 3:時 4:天
          occurrence: 0, // 0:未發生 1:發生
          type: 2, // 1:大便 2:小便
        },
      },
      {
        peroid: {
          id: 2,
          name: "便秘",
          code: "constipate",
          condition: 2, // 1:低於 2:超過
          duration: 72,
          unit: 3, // 1:秒 2:分 3:時 4:天
          occurrence: 0, // 0:未發生 1:發生
          type: 1, // 1:大便 2:小便
        },
      },
      {
        peroid: {
          id: 3,
          name: "極度腹瀉",
          code: "diarrhea",
          condition: 1, // 1:低於 2:超過
          duration: 240,
          unit: 2, // 1:秒 2:分 3:時 4:天
          occurrence: 1, // 0:未發生 1:發生
          type: 1, // 1:大便 2:小便
        },
        frequency: {
          id: 3,
          main_id: 1,
          condition: 2, // 1: '少於', 2: '超過'
          count: 3, // 次數
        },
        accumulation: {
          id: 3,
          main_id: 1,
          type: 2, // 1:單次 2:累計
          condition: 2, // 1: '低於', 2: '高於'
          weight: 1000, // 重量
          unit: 1, // 單位 1:公克 2:公斤
        },
      },
    ]);

    let formValues = reactive({
      name: "",
      prodUnit: 2,
      prdOccur: 1,
      prdType: 1,
      prdDuration: "",
      frqCond: 2,
      frqCount: "",
      accType: 2,
      accCond: 2,
      accWeight: "",
      accUnit: 1,
    });

    const DefaultValues = JSON.parse(JSON.stringify(formValues)); // 用於儲存預設值..即頁面新增項目時的預設值
    // 之後可能需要搬到 Vuex …
    const conditions = computed(() => {
      return parseNotificationString(notifyConditions.value)
    })

    let selectChange = (event) => {
      if (event.target.value === "1") {
        toggleAccmulate.value = true;
      } else {
        toggleAccmulate.value = false;
      }
      console.log("--", toggleAccmulate.value, event.target.value);
    };

    let deleteCondition = (id) => {
      console.log("deleteCondition", id, notifyConditions);
      notifyConditions.value = notifyConditions.value.filter(
        (item) => item.peroid.id !== id
      );
    };

    let restoreDefault = () => {
      Object.keys(DefaultValues).forEach((key) => {
        formValues[key] = DefaultValues[key];
      });
      toggleAccmulate.value = true;
    };

    let addCondition = (id) => {
      //let id =  ();
      id = id || notifyConditions.value.length + 1;
      let newAlertCondition = {
        peroid: {
          id: id,
          name: formValues.name,
          duration: formValues.prdDuration,
          unit: formValues.prodUnit, // 1:秒 2:分 3:時 4:天
          occurrence: formValues.prdOccur, // 0:未發生 1:發生
          type: formValues.prdType, // 1:大便 2:小便
        },
      };

      if (formValues.prdOccur > 0 && formValues.frqCond > 0) {
        newAlertCondition["frequency"] = {
          id: id,
          main_id: id,
          condition: formValues.frqCond, // 1: '少於', 2: '超過'
          count: formValues.frqCount, // 次數
        };
      }
      if (formValues.prdOccur > 0 && formValues.accType > 0) {
        newAlertCondition["accumulation"] = {
          id: id,
          main_id: id,
          type: formValues.accType, // 1:單次 2:累計
          condition: formValues.accCond, // 1: '低於', 2: '高於'
          weight: formValues.accWeight, // 重量
          unit: formValues.accUnit, // 單位 1:公克 2:公斤
        };
      }

      notifyConditions.value.push(newAlertCondition);
      restoreDefault();
    };

    const test = () => {
      // console.log('call validate()');
      // validate()
      console.log('call localeEditRef.value.addLocale');
      localeEditRef.value.addLocale(locale.value)

    }

    const parseNotificationString = (data) => {
      let output = [];
      const prdUnit = {
        1: "unit.second", // 秒鐘
        2: "unit.minute", // 分鐘
        3: "unit.hour",   // 小時
        4: "day",         // 天
      };

      const prdOccur = { 0: "has-not", 1: "has" };            // 0:沒有 1:有
      const prdType = { 1: "stool", 2: "urine" };             // 1:大便 2:小便
      const frqCond = { 1: "above-than", 2: "over-than" };    // 1:少於 2: 超過
      const accType = { 1: "single-time", 2: "accumulated" }; // 1:單次 2:累計 
      const accCond = { 1: "above-than", 2: "over-than" };    // 1:低於 2:高於
      const accUnit = { 1: "measure.g", 2: "measure.kg" };    // 1:公克 2:公斤

      data.forEach((alert) => {
        let name = alert.peroid.name;
        let id = alert.peroid.id;
        let code = alert.peroid.code || alert.peroid.name;
        let prd = alert.peroid;
        let frq = alert.frequency;
        let acc = alert.accumulation;
        let description = "";
        description = `${t("at")} <font color="red">${prd.duration
          }</font> <strong>
          ${t(prdUnit[prd.unit])}
        </strong> ${t("within")}「<strong>
          ${t(prdOccur[prd.occurrence])}
        </strong>」<font color="red">${t(prdType[prd.type])}</font>`;

        if (prd.occurrence === 1 && frq) {
          console.log(frq);
          description += `<br /> ${t("and") + " " + t("number-counts")
            }  <strong> 
            ${t(frqCond[frq.condition])}
          </strong><font color="red"> ${frq.count}</font> ${t('number-count')}`;
        }
        if (prd.occurrence === 1 && acc) {
          console.log("weight", acc);
          description += `
          <br />${frq ? t("and") : t("also")} ${t(accType[acc.type])} ${t(
            "weight"
          )} <strong>
            ${t(accCond[acc.condition])} 
          </strong><font color="red"> ${acc.weight} </font>
            ${t(accUnit[acc.unit])}
          `;
        }
        output.push({ id, name, description, code });
      });

      // console.log("condition:", output);

      return output
    }
    // 檢查已被選定的多國語系
    const localeInUse = computed(() => {
      return languages.reduce((result, locale) => {
        let t1 = localeList.value.reduce((result, item) => {
          let r1 = result || item.value.locale == locale
          return r1
        }, false)
        result[locale] = t1
        return result
      }, {})
    })
    const onSubmit = () => {
      handleSubmit((values) => {
        console.log(values);
        emit("onSubmit", values);
      })();
    };
    const localeEditRef = ref(null);

    return {
      conditions,
      toggleAccmulate,
      selectChange,
      deleteCondition,
      formValues,
      addCondition,
      modalDom,
      test,
      addNewLocale,
      addLocale,
      delLocale,
      localeList, validate, onSubmit,
      languages, localeInUse,
      errors, meta, localeEditRef
    };
  },
});
</script>

<style scoped>
.full-height {
  line-height: 38px;
  vertical-align: middle;
}
</style>