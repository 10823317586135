<template>
    <form @submit="onSubmit" :class="editClass">
        <div class="d-flex">
            <div class="flex-grow-1">
                <custom-input v-model="code" v-bind="codeProps" error-class="tooltip"
                    :placeholder="$t('enter-tranlate-code')" :classes="{ 'text-center': true }"
                    :readonly="mode !== 'add'" @toggle-edit="ToggleEdit" />
            </div>
            <div class="flex-grow-1">
                <custom-input v-model="description" v-bind="descriptionProps" error-class="tooltip"
                    :placeholder="$t('enter-description')" :classes="{ 'text-center': true }" />
            </div>
            <!-- <div class="flex-shirnk-1">456</div> -->
        </div>
        <div class="d-flex">

            <div class="flex-fill text-center">
                <div v-for="({ value: item }, idx) in localeList" :key="idx" class="d-flex my-1">
                    <div class="flex-shrink ">
                        <div class="position-relative">
                            <select class="form-select" v-model="item.locale"
                                :class="{ 'is-invalid': submitCount > 0 && errors[`locales[${idx}].locale`] }">
                                <option value="">{{ $t('please-select') }}</option>
                                <option v-for="(locale) in languages" :key="locale" :value="locale"
                                    :disabled="localeInUse[locale]">
                                    {{ $t(locale) + ' (' + locale + ')' }}</option>
                            </select>
                            <div class="text-start invalid-tooltip">
                                {{ errors[`locales[${idx}].locale`] }}
                            </div>
                        </div>
                    </div>
                    <div class="flex-fill">
                        <div class="position-relative">
                            <input class="form-control text-center" type="text"
                                :placeholder="$t('enter-tranlate-context')" v-model="item.text" name="fieldName"
                                :class="{ 'is-invalid': submitCount > 0 && errors[`locales[${idx}].text`] }" />
                            <div class="text-start invalid-tooltip">
                                {{ errors[`locales[${idx}].text`] }}
                            </div>
                        </div>
                    </div>
                    <div class="flex-shrink-1">
                        <button class="btn btn-danger mx-1 btn-sm" @click="delLocale(idx)"
                            :disabled="localeList.length === 1 || mode !== 'add'">
                            <font-awesome-icon icon="minus" :class="{ 'is-invalid': errors[`locales[${idx}].name`] }" />
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex flex-row-reverse">
            <!-- 新增 locales -->
            <div class="flex-shrink-1 mx-1">
                <button class="btn btn-primary btn-sm" :disabled="localeList.length === languages.length"
                    @click="addLocale()">
                    <font-awesome-icon icon="plus" />
                </button>
            </div>
            <!-- 確定 / 取消 / 刪除 按鍵 -->
            <div class="flex-grow-1 text-center">
                <button :disabled="!meta.dirty" type="submit" class="btn btn-link btn-lg ms-5">
                    <template v-if="!isSubmitting">
                        <font-awesome-icon icon="check" />
                    </template>
                    <template v-else>
                        <div class="spinner-border spinner-border-sm" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </template>
                </button>
                <button type="button" @click="onReset" class="btn text-danger btn-link btn-lg mx-1">
                    <font-awesome-icon icon="xmark" />
                </button>
                <button v-if="mode !== 'add'" type="button" :disabled="!editable || meta.dirty" @click="onDelete"
                    class="btn btn-link btn-lg mx-1" :class="{ 'text-info': editable, 'text-secondary': !editable }">
                    <font-awesome-icon class="fa-sm" icon="trash-can" />
                </button>
            </div>
        </div>

    </form>

    <!-- <div class="row">
        <div class="col">
            submitCount: {{ submitCount }}
        </div>
    </div>
-->
    <!-- <div class="row">
        <div class="col">
            meta: {{ meta }}
        </div>
    </div> -->

    <!-- <div class="row">
        <div class="col">
            values: {{ values }} <br />
        </div>
    </div> -->

    <!--
    <div class="row">
        <div class="col">
            errors: {{ errors }}
        </div>
    </div> -->

</template>

<script setup>
import CustomInput from '@/components/ui/CustomInput';
import { useForm, useFieldArray } from 'vee-validate';
import { computed, ref } from 'vue';
import { useI18n } from "vue-i18n";
import * as yup from 'yup';
import { wait_a_second } from '@/helper/daxin_utils';
import { UbusRequest } from '@/helper/ubus';
import { useStore } from 'vuex';

const store = useStore();

const { locale: i18nLocale } = useI18n();

const props = defineProps({
    initialValues: {
        type: Object,
    },
    mode: {
        type: String,
        default: 'edit'
    }
});

const emit = defineEmits(['onUpdated']);


let updatedValues;
const validationSchema = yup.object().shape({
    code: yup.string().required().label('translate code'),
    description: yup.string().required(),
    locales:
        yup.array()
            .of(
                yup.object({
                    text: yup.string().required().label('text'),
                    locale: yup.string().required().label('locale'),
                })
            ).strict(),
});

const { values, handleSubmit, resetForm, errors, meta, isSubmitting, defineField, submitCount, setValues } = useForm({
    initialValues:
        props.initialValues ||
        {
            locales:
                [{ locale: i18nLocale.value, text: '', id: '' }],
            // [{ "id": 1, "locale": "zh-TW", "text": "雲林縣政府" }, { "id": 2, "locale": "en-US", "text": "daxin biotechnology" }, { "id": 3, "locale": "ja-JP", "text": "ダキシンバイオ株式会社" }],
            code: '',
            description: '',
        },
    validationSchema,
    // validateOnMount: false // useFieldArray 沒用作用.. 
});

const [code, codeProps] = defineField('code', { props: state => ({ state }) });
const [description, descriptionProps] = defineField('description', { props: state => ({ state }) });


const { availableLocales: languages } = useI18n();
// const { remove: delLocale, push: pushLocale, fields: localeList } = useFieldArray("locales");
const { remove: delLocale, push: pushLocale, fields: localeList } = useFieldArray('locales');
const addLocale = (locale) => {
    pushLocale({ locale: locale || '', text: '', id: '' });
}
const localeInUse = computed(() => {
    return languages.reduce((result, locale) => {
        let t1 = localeList.value.reduce((result, item) => {
            let r1 = result || item.value.locale == locale
            return r1
        }, false)
        result[locale] = t1
        return result
    }, {})
})

// const ubus_base_params = { namespace: 'i18n', procedure: 'list' }

const onSubmit = handleSubmit(async (values) => {
    const initialValues = updatedValues || meta.value.initialValues
    const updated_locales = [];
    const result = { target: initialValues.code, mode: props.mode } // 預設為原有的 code 

    let params;

    if (props.mode === 'add') {
        // 如果是新增，要直接插入一筆新的資料到 i18n_code ..
        params = {
            namespace: 'i18n_code',
            procedure: 'query',
            data: {
                method: 'insert',
                code: values.code,
                description: values.description
            }
        }
    } else {
        if (values.code !== initialValues.code || values.description !== initialValues.description) {
            params = {
                namespace: 'i18n_code',
                procedure: 'query',
                data: {
                    method: 'update',
                    code: initialValues.code,
                    update_values: {
                        code: values.code,
                        description: values.description
                    }
                }
            }
        }
    }
    // 如果params 有值就 call ubus api 執行，不用管他是在幹嘛的(不重要！)
    if (params) {
        const res = await UbusRequest(params)
        console.log(1, { params, res })
        if (res.rows_count > 0) {
            result.merge = true;
            result.target = values.code; // 更新為新的 code
        }
        params = undefined;
    }

    for (let row of values.locales) {
        console.log(row);
        // 先查詢資料看是否有變更... 
        let originValues = initialValues.locales.find(initVal => initVal.locale === row.locale)
        if (originValues && originValues.text !== '') { // 有找到，比對資料是否相符 ... 
            console.log('originValues', originValues);
            if (originValues.text !== row.text) {
                // update it ... 
                params = {
                    namespace: 'i18n_list',
                    procedure: 'query',
                    data: {
                        method: 'update',
                        id: originValues.id,
                        update_values: {
                            text: row.text
                        }
                    }
                }
                console.log('text has been changed -> params:', params)
            }
        } else {
            // 不存在於 initialValues 代表這是一個全新的項目 ... 
            params = {
                namespace: 'i18n_list',
                procedure: 'query',
                data: {
                    method: 'insert',
                    code: values.code,
                    locale: row.locale,
                    text: row.text
                }
            }
            console.log('not exist insert it', params)
        }

        if (params) {
            const res = await UbusRequest(params);
            console.log(2, { params, res })
            if (res.rows_count > 0) {
                updated_locales.push(row.locale);
            }
        }
    }


    if (updated_locales.length > 0) {
        result.merge = true;
        result.updated_locales = updated_locales;
    }

    if (result.merge === true) {
        updatedValues = JSON.parse(JSON.stringify(values));
        setValues(values);
        console.log('updatedValues:', updatedValues, Object.keys(updatedValues).length);
    }

    emit('onUpdated', result);

})

// const test = async () => {
//     console.log('test has been called');
//     console.log(store.state.ui.comfirm_callback());
//     let result = await store.dispatch('ui/confirm', {
//         header: '確認刪除', body:
//             `確認刪除代碼<font color="red">test-text</font>？`,
//         size: 'sm', auto_close: 10
//     });
//     console.log('result:', result);
// }

const onDelete = async () => {
    console.log('onDelete has been called', values);
    let result = await store.dispatch('ui/confirm_modal', {
        header: '確認刪除', body:
            `確認刪除代碼<font color="red">${values.code}</font>？`,
        size: 'lg', auto_close: 10
    });
    if (result === true) {
        let params = { namespace: 'i18n_list', procedure: 'query', data: { code: values.code } }
        let raw_data = await UbusRequest(params);
        console.log('raw_data:', raw_data, raw_data.list);
        if (raw_data.rows_count > 0) {
            params = { namespace: 'i18n_list', procedure: 'query', data: { method: 'delete', code: raw_data.list[0].code } }
            console.log(1, params);
            let res = await UbusRequest(params)
            if (res.rows_count > 0) {
                params = { namespace: 'i18n_code', procedure: 'query', data: { method: 'delete', code: raw_data.list[0].code } }
                console.log(2, params);
                let res1 = await UbusRequest(params)
                if (res1.rows_count > 0) {
                    emit('onUpdated');
                }
            }
        }
    }

}

const editClass = computed(() => {
    if (props.mode === 'add') {
        return ["border", "border-danger", "rounded", "p-1"]
    } else {
        return []
    }
})

const onReset = () => {
    console.log('updatedValues:', updatedValues);
    if (!updatedValues) {
        resetForm();
    } else {
        setValues(updatedValues);
    }
}

const editable = ref(false);

const ToggleEdit = (locked) => {
    editable.value = !locked;
    // console.log('toggleEdit', editable.value);
}



</script>
