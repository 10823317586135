<template>
  <bs-card :head="t('device.name') + '：' + device_status.name" :foot="device_status.device_time > 0
    ? t('last-update-time') + '：' +
    d(new Date(device_status.device_time * 1000), 'long')
    : t('device.updating')
    " :refreshBtn="true" @refreshClicked="deviceStatusRefreshHandler">
    <template #body>
      <!-- 連線狀態 -->
      <div class="row my-1">
        <div class="col-4 text-end">{{ t('online-status') }}:</div>
        <div class="col-8" :class="{ 'text-danger': device_status.sanitize === 1 }">
          {{ device_status.sanitize === 1 ? t('in-sanitize-mode') : t(device_status.online_code) }}
        </div>
      </div>
      <template v-if="device_status.online_code === 'online'">
        <!-- 設備運行中 -->
        <div class="row">
          <div class="col-4 text-end">{{ t('device.on') }}:</div>
          <div class="col-4">
            {{ device_status.is_turned_on ? t('yes') : t('no') }}
          </div>
          <div class="col-4 text-end">
            <button class="btn btn-link" :disabled="device_status.sanitize === 1" @click="toggle_device()">
              <span v-if="device_status.is_turned_on">
                <font-awesome-icon class="fa-xl" icon="toggle-on" />
              </span>
              <span v-else>
                <font-awesome-icon class="fa-xl" icon="toggle-off" />
              </span>
            </button>
          </div>
        </div>
        <!-- 姓名設定 -->
        <div class="row">
          <div class="col-4 text-end mt-2">{{ t('device.username') }}</div>
          <div class="col-8 mt-2">
            <div class="row">
              <template v-if="!user_name_edit">
                <span @click="toggle_user_name_edit()">
                  {{ userName ? userName : t('not-set') }}
                </span>
              </template>
              <template v-else>
                <div class="col-8">
                  <custom-input :disabled="userNameSubmitting" v-model="userName" v-bind="userNameProps" type="text"
                    errorClass='tooltip' />
                </div>
                <div class="col-4 ">
                  <!-- 送出設定目標姓名 ✓ -->
                  <button class="btn btn-sm btn-link" :disabled="!meta.valid" @click="set_username()">
                    <div v-if="!userNameSubmitting"><font-awesome-icon icon="check" /></div>
                    <div class="text-nowrap" v-else>
                      <span class="spinner-border spinner-border-sm"> </span>
                    </div>
                  </button>
                  <!-- 取消 × -->
                  <button class="btn btn-sm btn-link" @click="toggle_user_name_edit()"><font-awesome-icon
                      icon="xmark" /></button>
                </div>
              </template>
            </div>
          </div>
        </div>
        <!-- 性別設定 -->
        <div class="row">
          <div class="col-4 text-end mt-2">{{ t('device.setgender') }}:</div>
          <div class="col-4 mt-2">
            {{
              device_status.gender === undefined
                ? t('unknown')
                : t(device_status.gender)
            }}
          </div>
          <div class="col-4 text-end">
            <!-- <span v-if="device_status.gender !== undefined" @click="$emit('toggle_gender', { mac_address })"
              class="btn">
              <span v-if="device_status.gender === 'male'">
                <font-awesome-icon class="fa-xl" icon="mars" />
              </span>
              <span v-else>
                <font-awesome-icon class="fa-xl" icon="venus" />
              </span>
            </span> -->

            <button class="btn btn-link" :disabled="device_status.process !== undefined || device_status.is_turned_on "
              @click="$emit('toggle_gender', { mac_address })">
              <span v-if="device_status.gender !== undefined">
                <span v-if="device_status.gender === 'male'">
                  <font-awesome-icon class="fa-xl text-primary " icon="mars" />
                </span>
                <span v-else>
                  <font-awesome-icon class="fa-xl text-danger" icon="venus" />
                </span>
              </span>
            </button>
          </div>
        </div>
        <!-- 目前水溫 -->
        <div class="row">
          <div class="col-4 text-end">{{ t('device.current_water_temperature') }}:</div>
          <div class="col-8">
            {{ device_status.current_water_temperature || t('unknown') }}
          </div>
          <!-- 目標水溫 -->
          <div class="col-4 text-end mt-1">{{ t('device.target_water_temerature') }}:</div>
          <div class="col-8 mt-1" v-if="water_temp_edit === false" @click="toggle_water_temp_edit()">
            {{ device_status.target_water_temperature || t('unknown') }}
          </div>
          <div class="col-8 mt-1" v-else>
            <div class="row">
              <div class="col-8">
                <custom-input :disabled="water_temp_submit" v-model="waterTemp" v-bind="waterTempProps" type="number"
                  min="25.0" max="37.0" step="0.1" errorClass='tooltip' inputmode="decimal" />
              </div>
              <!-- {{ errors }} {{ meta }} {{ values }} {{ waterTemp }} -->
              <div class="col-4 ">
                <!-- <div class="row flex-nowrap">
                  <div class="col"> -->
                <!-- 送出設定目標水溫 ✓ -->
                <button class="btn btn-sm btn-link" :disabled="!meta.valid" @click="set_watertemp()">
                  <div v-if="!water_temp_submit"><font-awesome-icon icon="check" /></div>
                  <div class="text-nowrap" v-else>
                    <span class="spinner-border spinner-border-sm"> </span>
                  </div>
                </button>
                <!-- </div> -->
                <!-- 取消 × -->
                <!-- <div class="col"> -->
                <button class="btn btn-sm btn-link" @click="water_temp_edit = false"><font-awesome-icon
                    icon="xmark" /></button>
                <!-- </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <!-- 水加熱中 -->
        <div class="row">
          <div class="col-4 text-end mt-1">{{ t('device.heating') }}:</div>
          <div class="col-8 mt-1">
            {{ device_status.is_water_heating ? t('yes') : t('no') }}
          </div>
        </div>
        <!-- 大便次數 -->
        <div class="row">
          <div class="col-4 text-end mt-1">{{ t('stool-count') }}:</div>
          <div class="col-8 mt-1">
            {{ device_status.stool_on_display || 0 }}
          </div>
        </div>
        <!-- 小便次數 -->
        <div class="row">
          <div class="col-4 text-end mt-1">{{ t('urinate-count') }}:</div>
          <div class="col-8 mt-1">
            {{ device_status.urinate_on_display || 0 }}
          </div>
        </div>
        <!-- 更新模式 -->
        <div v-if="device_status.mqtt_mode !== undefined" class="row">
          <div class="col-4 text-end mt-1">{{ t('update-mode') }}:</div>
          <div class="col-4 mt-1">
            {{ t(device_status.mqtt_mode + '-mode') }}
          </div>
          <div class="col-4 text-end">
            <span
              @click="$emit('toggle_mqtt_mode', { mac_address, mode: device_status.mqtt_mode == 'active' ? 'passive' : 'active' })">
              <span v-if="device_status.mqtt_mode == 'active'">
                <font-awesome-icon class="fa-xl" icon="toggle-on" />
              </span>
              <span v-else>
                <font-awesome-icon class="fa-xl" icon="toggle-off" />
              </span>
            </span>
          </div>
        </div>
      </template>
      <!-- {{ user_name }} -->
      <!-- 設備未上線 -->
      <!-- -- {{ t('device-not-online') }} -- -->
      <template v-if="device_status.online_code !== 'online'">
        <!-- 設備目前未上線 -->
        <div class="row">
          <div class="col text-center my-5 py-5 text-danger">
            -- 設備目前未上線 --
          </div>
        </div>
      </template>
      <div class="row">
        <div class="col text-center">
          <hr />
        </div>
      </div>
      <!-- 大小便遠端控制 -->
      <div class="row">
        <div class="col-6 my-1">
          <button class="w-100 btn btn-primary" :disabled="!device_available" @click="remote_control('stool')">
            {{ t('device.stool-mode') }}
          </button>
        </div>
        <div class="col-6 my-1">
          <button class="w-100 btn btn-primary" :disabled="!device_available" @click="remote_control('urinate')">
            {{ t('device.urinate-mode') }}
          </button>
        </div>
        <div class="col-6 my-1">
          <button class="w-100 btn btn-primary" :disabled="!device_available" @click="remote_control('rinsing')">
            {{ t('device.clean-mode') }}
          </button>
        </div>

        <div class="col-6 my-1">
          <button class="w-100 btn btn-primary" :disabled="!device_available" @click="remote_control('drying')">
            {{ t('device.drying-mode') }}
          </button>
        </div>
      </div>
      <!-- 設備告警 -->
      <div class="row" v-if="device_status.alert && device_status.alert.length > 0">
        <div class="col text-center">
          <div class="col-3 d-flex">
            <span class="badge bg-danger mx-1" v-for="(alert, idx) in device_status.alert" :key="idx">
              <font-awesome-icon icon="exclamation-triangle" class="text-light blink_me" />
              {{ t('alert_name.' + alert.code) }}
            </span>
          </div>
        </div>
      </div>
      <div class="row" v-else>
        <div class="col text-center text-danger">--- {{ t('device.noalert') }} ---</div>
      </div>
      <hr />
      <!-- 排遺記錄 -->
      <device-log :mac_address="mac_address" />
      <hr />
      <!-- 設備於清消模試內 -->
      <!-- <div v-if="device_status.sanitize === 1" class="row">
        <div class="col text-center text-danger mb-3">
          --- 設備於清消模式 ---
        </div>
        <hr />
      </div> -->
      <!-- 設備運作程序 -->
      <div v-if="device_status.process === undefined" class="row">
        <div class="col text-center text-danger">--- {{ t('operation.none') }} ---</div>
      </div>
      <template v-else>
        <div class="row">
          <div class="col-4 text-end">{{ t('device.process') }}:</div>
          <div class="col-8">
            {{ t('status.' + device_status.status_code) }}
            <span v-if="device_status.status_source !== 'none'">
              ({{ t('trigger.' + device_status.process.triggered_from_code) }})
              <!-- ({{ device_triggered_name[device_status.process.triggered_from_code] }}) -->
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-4 text-end">{{ t('device.cycle') }}:</div>
          <div class="col-8">
            <!-- 正在執行 {{ device_status.process.current_process_count }} /
            {{ device_status.process.total_process_count }} 個循環 -->
            {{ t('cycle.description', {
              current_count: device_status.process.current_process_count, total_count:
                device_status.process.total_process_count
            }) }}
          </div>
        </div>
        <div class="row">
          <div class="col-4 text-end">{{ t('device.operating-name') }}:</div>
          <div class="col-8">
            {{ t('process.' + device_status.process.process_code) }}
            <span v-if="device_status.process.process_code &&
              device_status.process.process_code !== 'none'
            ">
              ({{ device_status.process.process_remained_duration / 10 }} /
              {{ device_status.process.process_total_duration / 10 }} {{ t('second') }})
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-4 text-end">{{ t('device.waiting-name') }}:</div>
          <div class="col-8">
            {{ t('wait.' + device_status.process.waiting_code) }}
            <span v-if="device_status.process.waiting_code &&
              device_status.process.waiting_code !== 'none'
            ">
              ({{ device_status.process.process_remained_duration / 10 }} /
              {{ device_status.process.process_total_duration / 10 }} {{ t('second') }})
            </span>
          </div>
        </div>
      </template>
      <div v-if="false" class="row">
        <div class="col text-end">
          <button type="button" class="btn-primary btn px-1 btn-sm" @click="$emit('notify_edit_click', mac_address)">
            {{ $t("settings") }}
          </button>
        </div>
      </div>
    </template>
  </bs-card>
</template>

<script>
import { defineComponent, ref, computed, onMounted, watch } from "vue";
import CustomInput from "@/components/ui/CustomInput";
import BsCard from "@/components/bootstrap/BsCard.vue";
import DeviceLog from "@/components/DeviceLog.vue";
import { useI18n } from "vue-i18n";
import * as yup from 'yup';
import { useForm } from 'vee-validate';
import { useStore } from 'vuex';
import { wait_a_second } from '@/helper/daxin_utils';

export default defineComponent({
  name: "DeviceBlock",
  props: {
    device_status: {
      required: true,
      type: Object,
    },
    mac_address: {
      required: true,
      type: String,
    },
    user_name: {
      type: String
    }
  },
  components: { BsCard, DeviceLog, CustomInput }, // eslint-disable-line
  emtis: ["remote_control", "set_watertemp", "toggle_device", "refresh_status", "toggle_mqtt_mode", "notify_edit_click", "refresh_name"],

  setup(props, { emit }) {
    const store = useStore();
    const { d, t, locale } = useI18n();

    onMounted(() => {
      //
      setFieldValue('device_user_name', props.user_name || t('not-set'));
      setFieldValue('target_water_temperature', props.device_status?.target_water_temperature || 30);
    })

    const validationSchema = yup.object().shape({
      target_water_temperature: yup.number().required().min(25).max(37).label('water_temperature'),
      device_user_name: yup.string().required().label('username')
    })

    const { errors, meta, defineField, setFieldValue, values, isSubmitting } = useForm({ validationSchema });
    const [waterTemp, waterTempProps] = defineField('target_water_temperature', { props: state => ({ state }) });
    const [userName, userNameProps] = defineField('device_user_name', { props: state => ({ state }) });


    const device_log = {};
    const water_temp_edit = ref(false);
    const toggle_water_temp_edit = () => {
      water_temp_edit.value = true;
    };


    // 2024-05-27 使用者名稱編輯 start.
    const user_name_edit = ref(false);
    const toggle_user_name_edit = () => {
      if (user_name_edit.value === true) {
        user_name_edit.value = false;

      } else {
        user_name_edit.value = true;
      }
      setFieldValue('device_user_name', props.user_name);
    }

    watch(() => props.user_name, (newVal) =>
      setFieldValue('device_user_name', newVal)
    )

    const userNameSubmitting = ref(false);

    const set_username = async () => {
      userNameSubmitting.value = true;
      let method = 'insert';
      if (props.user_name) {
        method = 'update';
      }
      let res = await store.dispatch('ubus/setDeviceUserName', { method, name: userName.value, device_mac: props.mac_address });
      // if (res.error === true) {
      emit("refresh_name");
      // }
      await wait_a_second(1000);
      userNameSubmitting.value = false;
      user_name_edit.value = false;
    }


    // 2024-05-27 使用者名稱編輯 end.
    const water_temp_submit = ref(false);
    const set_watertemp = () => {
      water_temp_submit.value = true;
      emit("set_watertemp", {
        temperature: waterTemp.value,
        mac: props.mac_address,
      });
      deviceStatusRefreshHandler();
      setTimeout(() => {
        water_temp_submit.value = false;
        water_temp_edit.value = false;
      }, 3000);
    };

    const remote_control = (mode) => {
      emit("remote_control", { mode, mac: props.mac_address });
    };

    const toggle_device = () => {
      let status = "off";
      if (props.device_status.is_turned_on === false) {
        status = "on";
      }
      emit("toggle_device", { status, mac: props.mac_address });
    };


    const device_available = computed(() => {
      let result = false
      if (props.device_status.status_code !== undefined &&
        props.device_status.status_code === "standby") {
        result = true;
      }
      if (props.device_status.sanitize === 1) {
        result = false
      }
      return result
    });

    const device_alert_names = {
      water_shortage: t('alert_name.water_shortage'), //"缺水",
      waste_bucket_misplaced: t('alert_name.waste_bucket_misplaced'), //"糞桶位移",
      waste_bucket_full: t('alert_name.waste_bucket_full'), //"糞桶滿溢",
      hardware_error: t('alert_name.hardware_error'), //"空燒故障",
      water_overheated: t('alert_name.water_overheated'), //"水溫過高",
      stool_needs_flush: t('alert_name.stool_needs_flush'), //"大便待處理",
    };

    const device_waiting_names = {
      none: t('wait.none'), //"無",
      "vaccum-flush": t('wait.vaccum-flush'), //"負壓至沖水間隔時間",
      "flush-wash": t('wait.flush-wash'), //"沖水至沖洗間隔時間",
      "process-wait": t('wait.process-wait'), //"行程等待時間",
    };

    const device_process_names = {
      none: t('process.none'), //"無",
      internal_water_cycle: t('process.internal_water_cycle'), //"內循環",
      water_flushing: t('process.water_flushing'), //"沖水",
      water_washing: t('process.water_washing'), //"清洗",
      air_blowing: t('process.air_blowing'), //"吹氣中",
      hot_air_drying: t('process.hot_air_drying'), //"烘乾中",
    };

    const device_status_names = ref({
      off: t('status.off'), //"停止運作",
      standby: t('status.standby'), //"待機",
      stool: t('status.stool'), //"大便模式",
      urinate: t('status.urinate'), //"小便模式",
      drying: t('status.drying'), //"烘乾模式",
      rinsing: t('status.rinsing'), //"清洗模式",
    })

    const device_triggered_name = {
      none: t('trigger.none'), //"無",
      sensor: t('trigger.sensor'), //"感測器觸發",
      hmi: t('trigger.hmi'), //"人機觸發",
      modbus: t('trigger.modbus'), //"遠端控制",
    };

    const deviceStatusRefreshHandler = (id) => {
      emit("refresh_status", { mac_address: props.mac_address });
    }

    return {
      device_triggered_name,
      device_status_names,
      device_alert_names,
      device_process_names,
      device_waiting_names,
      device_log,
      water_temp_edit,
      water_temp_submit,
      set_watertemp,
      toggle_device,
      device_available,
      remote_control,
      toggle_water_temp_edit,
      t, d,
      deviceStatusRefreshHandler,
      waterTemp, waterTempProps,
      meta, errors, values, isSubmitting,
      userName, userNameProps, toggle_user_name_edit, user_name_edit, set_username, userNameSubmitting
    };
  },
});
</script>

<style>
.modal {
  z-index: 10040;
}
</style>

<style lang="scss">
@import "~bootstrap/scss/bootstrap";
</style>