<template>
  <div class="container-xxl">
    <!-- <div class="row">
      <div class="col">
        alertList: {{ alertList }}
      </div>
    </div> -->
    <div class="row" v-if="isLoggedIn === false">
      <!-- 未登入時顯示 -->
      <div class="row">
        <div class="col text-center text-danger display-6 mt-5"> {{ $t('not-logged-in') }}</div>
      </div>
    </div>
    <div class="row" v-if="isLoggedIn === true">
      <!-- 登入後顯示 -->
      <div class="d-flex flex-row-reverse mt-3 ">
        <div class="form-check-reverse form-switch mx-2">
          <input class="form-check-input" type="checkbox" role="switch" id="hideOfflineDevice"
            @click="toggleShowOffline()">
          <!-- 顯示/不顯示未上線設備 -->
          <label class="form-check-label" for="hideOfflineDevice">
            {{ showOfflineDevice ? $t('show-offline-device') : $t('do-not-show-offline-device') }}
          </label>
        </div>
        <div class="form-check-reverse form-switch mx-2">
          <input class="form-check-input" type="checkbox" role="switch" id="sortBy" @click="toggleSortBy()">
          <!-- 依時間/設備名稱排序 -->
          <label class="form-check-label" for="sortBy">
            {{ sortBy === 'device_name' ? $t('sort-by-device-name') : $t('sort-by-update-time') }}
          </label>
        </div>
        <div class="px-2" v-show="show_filter">
          <button :disabled="device_filter.length === 0" class="btn btn-primary" @click="clear_filter()">{{ $t('clear')
            }}</button>
        </div>
        <div class="px-2" v-show="show_filter">
          <input class="form-control" v-model="device_filter" @keyup="updateDeviceFilter()" />
        </div>
        <div class="px-2">
          <!-- 顯示/隱藏篩選器 -->
          <button class="btn btn-primary" @click="show_filter = !show_filter"> {{ show_filter ? $t('hide-filter') :
            $t('show-filter') }}</button>
        </div>
        <div class="px-2">
          <!-- deviceNames: {{ deviceNames }} -->
        </div>
      </div>

      <div class="row ">
        <!-- for each 設備資訊 -->
        <div class="col-lg-4 d-flex align-items-stretch mt-3" v-for="(device_data) in device_status"
          :key="device_data.mac">
          <device-block :device_status="device_data" @remote_control="remote_control" @set_watertemp="set_watertemp"
            @toggle_device="toggle_device" @refresh_status="refresh_status" @toggle_mqtt_mode="toggle_mqtt_mode"
            @toggle_gender="toggle_gender" :mac_address="device_data.mac" @notify_edit_click="notify_setting_edit"
            :user_name="deviceNames[device_data.mac]" @refresh_name="updateDeviceNames()" />
        </div>
      </div>

      <!-- 下拉式選單 不再顯示，待刪 -->
      <div class="row my-3">
        <div class="col-md-6 col-12">
          <!-- <select v-model="mac_address" class="form-select">
            <option v-for="detail in device_status" :key="detail.mac" :value="detail.mac">
              {{ detail.name }} - {{ detail.mac }} ({{ $t(detail.online_status) }})
            </option>
          </select> -->
        </div>
      </div>
      <div class="row">
        <div class="col">

        </div>
      </div>
    </div>
  </div>
  <notify-settings v-model:mac_address="device_mac" />
</template>

<script>
import { defineComponent, ref, computed, watch, onMounted } from "vue";

import BsCard from "@/components/bootstrap/BsCard.vue";
import DeviceBlock from "@/components/DeviceBlock.vue";

import { mqtt } from "@/helper/mqtt";
import { useStore } from "vuex";
import { Modal } from "bootstrap"; // 1. 先載入 Modal 元件
import { useI18n } from "vue-i18n";
import NotifySettings from "@/components/modal/NotifySettings.vue"; // 告警設定

export default defineComponent({
  name: "mqtt",
  components: { BsCard, DeviceBlock, NotifySettings }, // eslint-disable-line
  setup() {

    const { t } = useI18n();
    const store = useStore();

    let modalDom, modalInstance; // 2. 宣告用於儲存 modal DOM 及 Instance 的變數
    // const device_list = ref({
    //   Dx005: { mac: "445f7a001803", status: "online" }, // 桌上測試機
    //   DX004: { mac: "445f7a001384", status: "offline" }, // 榮家測試機
    //   xx002: { mac: "aabbccddeeff02", status: "unknown" },
    // });
    // 是否登入 ... 
    const isLoggedIn = computed(() => store.getters["auth/isLoggedIn"]);

    watch(isLoggedIn, async (newVal) => {
      if (newVal === false && intervalId.value > 0) {
        clearInterval(intervalId.value);
        intervalId.value = 0;
      }
    });

    const deviceRefreshRate = ref(1000); // in millisecond
    const intervalId = ref(0);
    const device_status = computed(() => store.getters['device/getDeviceStatistic']);

    onMounted(async () => {
      if (isLoggedIn.value === true) {
        await store.dispatch('ubus/getDeviceStatistic');
        // 若頁面事入時 已有登錄，則要求所有的設備推送當前的設備狀態
        mqtt.publish({}, "daxin/all/get_status");

        await store.dispatch("ubus/getDeviceStatus");
        // 新增 interval 更新 device_list
        if (intervalId.value === 0) {
          intervalId.value = setInterval(async () => {
            await store.dispatch("ubus/getDeviceStatus"); // 這邊壞掉了，要修
          }, deviceRefreshRate.value);
        }
        // 3. 找到 Modal 並儲存於 Dom 變數之中
        modalDom = document.querySelector("#alertSettingModal");
        modalInstance = Modal.getOrCreateInstance(modalDom);
        // 2024-05-27 透過 ubus/updateDeviceNames 取得所有設備的名稱 ... 
        await updateDeviceNames();
      }
    });

    const device_mac = ref(''); // 用於傳入 modal 中的 v-model

    const notify_setting_edit = (mac) => {
      device_mac.value = mac;
      if (modalInstance !== undefined) {
        modalInstance.show();
      }
    }

    mqtt.connect('dashboard', { debug: true });

    mqtt._debug = false;
    const mac_address = ref("445f7a001803");
    const remote_control = ({ mode, mac }) => {
      if (["stool", "urinate", "drying", "rinsing"].includes(mode)) {
        mqtt.publish({ operation: mode }, "daxin/" + mac + "/control");
      }
    };

    const toggle_device = ({ status, mac }) => {
      mqtt.publish({ status }, "daxin/" + mac + "/control");
    };

    const toggle_gender = (params) => {
      mqtt.publish({ gender: 'toggle', }, 'daxin/' + params.mac_address + '/control');
    };

    const set_watertemp = ({ temperature, mac }) => {

      mqtt.publish({ temperature }, "daxin/" + mac + "/control");
    };

    const alertList = ref({});

    // control 為設備端才需要處理的項目，dashboard不需要做任何處理
    mqtt.add_listener("daxin/" + "+" + "/control");

    const update_status = (msg, topic) => {
      let mac = topic.match("daxin/(.*?)/status")[1];
      msg.mac = mac;
      // console.log(msg.alert);
      if (msg.alert.length !== undefined) {
        if (alertList.value[mac] === undefined) {
          alertList.value[mac] = [];
        }
        // 檢查是否有新的告警項目 ... 
        for (const list of msg.alert) {
          // 若在 alertList 中不存在，則發布 notify ... 後加入 array
          const error_code = list.code;
          if (!alertList.value[mac].includes(error_code)) {
            console.log('new error', error_code, msg);
            // add notify here
            store.dispatch('notification/addNotify',
              {
                title: "設備異常",
                body: `設備「${msg.name}」，發生「${t(error_code)}」異常，請前往進行處理。`,
                keepOn: true
              }
            );
            alertList.value[mac].push(error_code);
          }
        }
        // 檢查是否有告警項目移除 ... 
        alertList.value[mac] = alertList.value[mac].filter((error_code) => {
          return msg.alert.some(row => row.code === error_code)
        });

      } else if (alertList.value[mac]) {
        //
        alertList.value[mac] = []
      }
      store.dispatch('device/updateDeviceStatistic', msg)
    };

    mqtt.add_listener("daxin/" + "+" + "/status", update_status);

    const mqtt_notify = (payload, topic) => {
      let mac = topic.match("daxin/(.*?)/notify")[1];
      let target_device = device_status.value.find(device => device.mac === mac)
      if (target_device) {
        let message = '由「' + target_device.name + '」傳送的測試訊息'
        if (payload.msg) {
          message += ':<br />&nbsp;&nbsp;' + payload.msg
        }
        let title = 'MQTT測試訊息' + '(' + mac + ')'
        addToast({
          title,
          message,
          closeAfter: -1
        })
      }
    }

    const sortBy = computed(() => store.state.device.sortBy);
    const toggleSortBy = () => {
      store.commit('device/toggleSortBy')
    }

    mqtt.add_listener("daxin/" + "+" + "/notify", mqtt_notify);

    const refresh_status = param => {
      mqtt.publish({}, "daxin/" + param.mac_address + "/get_status");
    }

    const toggle_mqtt_mode = (param) => {
      mqtt.publish({ mode: "toggle" }, "daxin/" + param.mac_address + "/get_status");
    };

    const targetTemp = ref(0);
    const device_log = ref({});
    const deviceLogRange = ref(3);

    // 是否顯示離線設備
    const showOfflineDevice = computed(() => store.state.device.show_offline);
    const toggleShowOffline = () => { // 切換 顯示 / 不顯示 離線設備
      store.commit('device/toggleShowOffline');
    }
    // 顯示 / 不顯示 篩選器
    const show_filter = ref(false);
    const device_filter = ref(''); //  篩選文字
    const clear_filter = () => {
      device_filter.value = '';
      store.commit('device/updateDeviceFilter', device_filter.value);
    }
    const updateDeviceFilter = () => {
      store.commit('device/updateDeviceFilter', device_filter.value);
    }

    const deviceNames = ref({});

    const updateDeviceNames = async () => {
      deviceNames.value = await store.dispatch('ubus/getDeviceUserNames');
    }

    return {
      alertList,
      updateDeviceFilter,
      toggleShowOffline,
      device_filter,
      show_filter,
      clear_filter,
      deviceLogRange,
      targetTemp,
      mac_address,
      device_status,
      toggle_device,
      toggle_gender,
      set_watertemp,
      remote_control,
      device_log,
      isLoggedIn,
      intervalId,
      deviceRefreshRate,
      refresh_status,
      toggle_mqtt_mode,
      device_mac, // 傳入 mac 
      notify_setting_edit, // 於 device block
      showOfflineDevice,
      sortBy, // 排序 依時間 或 設備名稱 …
      toggleSortBy,
      deviceNames,
      updateDeviceNames
    };
  },
});
</script>

<style></style>