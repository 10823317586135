<template>
    <div class="position-relative">
        <label v-if="label" :for="name"> {{ $t(label) }}</label>
        <template v-if="options">
            <select class="form-select" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)"
                @blur="$emit('blur')" :id="name" :class="validation_class">
                <option value="">請選擇</option>
                <option v-for="(option, idx) in options" :key="idx" :value="option.value" :disabled="option.disabled">
                    {{ option.name || option.value || idx }} {{ option.disabled && '(停用)' }}
                </option>
            </select>
        </template>
        <template v-else>
            <div class="input-group">
                <input class="form-control" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)"
                    @blur="$emit('blur')" :id="name" :class="validation_class" :placeholder="placeholder" :type="type"
                    @mousedown.ctrl="$event.target.value = ''" autocomplete="off" v-bind="attribute" :max="max"
                    :min="min" :step="step" :pattern="pattern" :inputmode="inputmode" :disabled="locked" />
                <button v-if="readonly !== false && editable === true" class="btn btn-outline-secondary bg-dark "
                    type="button" @click="toggleLock">
                    <template v-if="locked">
                        🔒
                    </template>
                    <template v-else>
                        🔓
                    </template>
                </button>
            </div>
        </template>
        <div :class="'invalid-' + errorClass">
            {{ state.errors[0] }}
        </div>
    </div>
    <!-- {{ validation_class }} {{ state }} -->
    <!-- {{ attribute }} -->
</template>

<script>

import { inject, computed, ref } from "vue";

export default {
    props: {
        name: {
            type: String,
            require: true,
            default: () => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1)
        },
        label: {
            type: String,
            require: false
        },
        errorClass: {
            type: String,
            default: 'feedback'
        },
        placeholder: {
            type: String,
        },
        type: {
            type: String,
            default: 'text'
        },
        options: {
            type: Object,
        },
        modelValue: {
            required: true,
            // type: String,
            default: ''
        },
        state: {
            default: () => { return {} }
        },
        inputType: {
            default: 'text'
        },
        classes: {
            type: Object,
            default: () => {
                return {}
            }
        },
        readonly: {
            type: Boolean,
            default: () => false
        },
        editable: {
            type: Boolean,
            default: () => true
        },
        min: String,
        max: String,
        step: String,
        attribute: Object,
        pattern: String,
        inputmode: String,
    },
    emits: ['update:modelValue', 'blur', 'change', 'input', 'toggle-edit'],
    setup(props, { emit }) {
        const validation_class = computed(() => {
            return { 'is-invalid': props.state.errors[0], 'is-valid': props.state.validated, ...props.classes }
        });

        let label_name = () => props.label;

        if (!label_name && inject('name_as_label') === true) {
            label_name = () => props.name
        }

        const locked = ref(props.readonly);

        const toggleLock = () => {
            locked.value = !locked.value
            emit('toggle-edit', locked.value);
        }
        return { validation_class, label_name, locked, toggleLock }
    }
}
</script>

<style scoped>
/* .input-group {
    display: inline;
} */
</style>